<template lang="pug">
.brand-themes-box(v-if="theme.thumbnail && theme.thumbnail.length" :style="background")
  .brand-themes-box-name(:style="{ color: this.theme.textColor }") {{ theme.name }}
  .brand-themes-box-lead(:style="{ color: this.theme.textColor }") {{ lead }}
  om-button(
    primary
    @click="$router.push({ name: 'theme_details', params: { themeName: theme.name } })"
  ) {{ $t('details') }}
</template>
<script>
  import { get as _get } from 'lodash-es';

  export default {
    props: {
      theme: {
        type: Object,
        default: () => {},
      },
    },

    computed: {
      background() {
        return {
          'background-image': `url(${this.theme.thumbnail})`,
        };
      },
      lead() {
        const locale = this.$i18n.locale;
        return _get(this.theme, `lead.${locale}`, '');
      },
    },
  };
</script>

<style lang="sass">
  .brand-themes-box
    padding: 5.375rem 7.25rem
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    border-radius: 1.875rem
    max-width: 75rem
    margin-left: auto
    margin-right: auto

    &-name
      font-family: 'Merriweather', serif
      font-weight: bold
      font-size: 3.375rem
      line-height: 4.25rem
      text-align: left

    &-lead
      max-width: 21.875rem
      margin-top: 0.75rem
      margin-bottom: 3.125rem
      font-size: 0.9375rem
      line-height: 1.5rem
      color: #4F4F4F
      text-align: left

    .brand-btn
      width: 8.125rem
</style>
