<template lang="pug">
.brand-themes(:class="rootClass")
  inline-svg.brand-themes-svg(
    :src="require('@/assets/admin/svg/themes-top.svg')"
    :style="svgColor"
    :class="svgClass"
  )
  template-progress-bar
  .brand-themes-container
    template-breadcrumb
    slot(name="title")
      om-heading.brand-themes-title(h2)
        span(v-if="themeName") {{ themeName }} {{ $t('theme') }}
        span(v-else) {{ $t('themes') }}
    slot(name="lead")
      .brand-themes-lead(v-if="$te(`templateThemes.lead.${themeName}`)") {{ $t(`templateThemes.lead.${themeName}`) }}
      .brand-themes-lead(v-else v-html="$t('templateThemes.lead.default')")
    slot
</template>

<script>
  import TemplateProgressBar from '@/components/Template/Progress/ProgressBar.vue';
  import TemplateBreadcrumb from '@/components/Template/Themes/Breadcrumb.vue';
  import { get as _get } from 'lodash-es';

  export default {
    components: { TemplateBreadcrumb, TemplateProgressBar },

    props: {
      color: {
        type: String,
        default: null,
      },
    },

    computed: {
      rootClass() {
        return this.themeName ? 'brand-themes-details' : 'brand-themes-list';
      },
      svgClass() {
        if (this.themeName) {
          return [`brand-themes-${this.themeName.toLowerCase()}`];
        }

        return null;
      },
      themeName() {
        return _get(this, '$route.params.themeName');
      },
      svgColor() {
        return {
          color: this.color,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-themes
    position: relative

    &-svg
      position: absolute
      top: 0
      left: 0
      z-index: -1
      width: 100%
      height: auto
      color: #f5f5f5

    &-title
      margin-top: .875rem
      margin-bottom: .875rem

    &-lead
      font-size: 0.9375rem
      line-height: 1.5rem
      color: #4F4F4F

    &-container
      padding: 2.5rem 4.875rem
      .brand-themes-list &
        text-align: center
</style>
