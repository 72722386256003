<template lang="pug">
.brand-themes-breadcrumb
  router-link(:to="{ name: 'new_campaign' }") {{ $t('allThemes') }}
  span.mx-2 |
  router-link(:to="{ name: 'themes_list' }" exact) {{ $t('themes') }}
  template(v-if="themeName")
    span.mx-2 |
    span {{ themeName }}
</template>
<script>
  export default {
    computed: {
      themeName() {
        return this.$route.params.themeName;
      },
    },
  };
</script>

<style lang="sass">
  .brand-themes-breadcrumb
    color: #828282
    font-size: 0.8125rem
    line-height: 1.5rem

    .router-link-active
      color: #828282
</style>
