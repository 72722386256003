<template lang="pug">
ThemesHeading
  .brand-themes-container
    theme-box(v-for="theme in showableThemes" :key="theme.name" :theme="theme")
</template>
<script>
  import ThemesHeading from '@/components/Template/Themes/Heading.vue';
  import ThemeBox from '@/components/Template/Themes/ThemeBox.vue';

  import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';

  export default {
    components: { ThemeBox, ThemesHeading },

    data: () => ({ themes: [] }),
    computed: {
      showableThemes() {
        return this.themes.filter(({ multicolor, themeKit }) => {
          if (!multicolor) return true;
          return !!themeKit?.colors;
        });
      },
    },
    apollo: {
      themes: {
        query: GET_TEMPLATE_THEMES,
      },
    },
  };
</script>

<style lang="sass">
  .brand-themes
    &-container
      .brand-themes-box
        margin-bottom: 3.75rem

        &:first-child
          margin-top: 3.75rem
        &:last-child
          margin-bottom: 0
</style>
